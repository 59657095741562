import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";

import img1 from "../assets/images/home/home_1.jpg";
import img2 from "../assets/images/home/home_2.jpg";
import img3 from "../assets/images/home/home_3.jpg";
import img4 from "../assets/images/home/home_4.jpg";
import img5 from "../assets/images/home/home_5.jpg";
import iconSearch from "../assets/images/icons/icons8-search.svg";
import bannergradient from "../assets/images/gradients/banner-gradient.png";
import moon1 from "../assets/images/shapes/element-moon1.png";
import moon2 from "../assets/images/shapes/element-moon2.png";
import { useState } from "react";
import video from '../assets/images/home/video_campo_calcio.mp4';
const BannerOne = () => {
  function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
      <button className={className} onClick={onClick}>
        <i className="las la-arrow-right fs-2" />
      </button>
    );
  }

  function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
      <button className={className} onClick={onClick}>
        <i className="las la-arrow-left fs-2"></i>
      </button>
    );
  }
  const navigate = useNavigate();
  const [inputValue,setInputValue] = useState("");
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    navigate('/prodotti', { state: { searchQuery: inputValue } });
  };
  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
      responsive: [
        {
            breakpoint: 1024, // Tablet
            settings: {
                arrows: false,
                dots: true
            }
        },
        {
            breakpoint: 767, // Mobile
            settings: {
                arrows: false,
                dots: false,
            }
        }
    ]
  };
  const images = [
    img1,img2,img3,img4,img5
  ];

  return (
    <>
      <section className="hero section-bg z-index-1">
        <img
          src={bannergradient}
          alt=""
          className="bg--gradient white-version"
        />
        <div className="container mw-100 fluid">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-5  order-lg-1 order-2 ">
              <div className="hero-inner position-relative pe-lg-5">
                <div>
                  <h2 className="hero-inner__title mt-3 color-title-new">
                    Impianti di illuminazione a led
                  </h2>
                  <h2 className="fs-2 mt-3">
                    Specializzati in progetti su misura e consulenza
                  </h2>
                  <p className="mt-3">
                  Lediamoluce è un’azienda in grado di offrire <span className="fw-bold">soluzioni personalizzate</span> per le esigenze specifiche di clienti che operano come rivenditori o come partite iva.
                  </p>
                  <p className="mt-3 mb-3">
                  I nostri prodotti sono realizzati con materiali di <span className="fw-bold">alta qualità</span> per garantire <span className="fw-bold">prestazioni ottimali</span> e <span className="fw-bold">lunga durata</span> ad un prezzo competitivo. Scegli la qualità senza compromessi!</p>
                  
                  <form onSubmit={handleSubmit}>
                  <div className="search-box">
                    <input
                      type="text"
                      className="common-input common-input--lg pill shadow-sm"
                      placeholder="Cerca tra i nostri prodotti"
                      value={inputValue}
                      onChange={handleInputChange}
                    />
                    <button
                      type="submit"
                      className="btn-new btn-icon icon border-0"
                    >   
                    <img src={iconSearch} ></img>
                    </button>
                  </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-lg-6 order-lg-2 order-1">
              {/* <div className="hero-thumb"> */}
              <div className="popular-slider arrow-style row">
              {/* <video src={video} autoPlay={true} loop={true} controls="controls"/> */}
                <Slider {...settings}>
                  {images.map((img, index) => (
                    <div key={index}>
                      <img src={img} alt={`slide ${index + 1}`} className="img-fluid rounded"/>
                    </div>
                  ))}
                </Slider>
                </div>
              {/* </div> */}
            </div>
          </div>
        </div>
        
      </section>
    </>
  );
};

export default BannerOne;
