import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Preloader from "../helper/Preloader";
import HeaderOne from "./HeaderOne";
import FooterOne from "./FooterOne";
import Slider from "react-slick";
import Comment from './Comment';
import Form from './Form';
import CommentForm from "./CommentForm";
import { Helmet } from "react-helmet";
const ProductDetails = ({ productId }) => {
    const formLayout = {
        name:'product',title:"Commenta un prodotto e ti risponderemo via mail",layout:[
            {fieldName:'autore',fieldDimensions:12,fieldLabel:'Nome e cognome',fieldValidation:{required:true},fieldType:'text'},
            {fieldName:'email',fieldDimensions:6,fieldLabel:'Indirizzo email',fieldValidation:{required:true},fieldType:'email'},
            {fieldName:'contenuto',fieldDimensions:12,fieldLabel:'Commento',fieldValidation:{required:true},fieldType:'textarea'},
        ]
    }    
    const [product, setProduct] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [media, setMedia] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    const [isZoomed, setIsZoomed] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');

    const goToContactPage = () =>{
        navigate('/contatti',{state:product.nome})
    }
    // Function to fetch product data from the API
    const fetchProduct = async () => {
        try {
            const response = await fetch(`/api/products/${productId}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setProduct(data);
            console.log(data);
            setMedia([{url:data.foto1,type:"img"},{ url:data.foto2,type:"img"}, { url:data.foto3,type:"img"},{ url:data.video,type:"video"}].filter(i => i.url !== '/uploads/assets/images/products/fnd.jpg' && i.url !== "" && i.url != null));
        } catch (error) {
            console.log(error);
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    function SampleNextArrow(props) {
        const { className, onClick } = props;
        return (
            <button className={className} onClick={onClick}>
                <i className="las la-arrow-right m-10" />
            </button>
        );
    }

    function SamplePrevArrow(props) {
        const { className, onClick } = props;
        return (
            <button className={className} onClick={onClick}>
                <i className="las la-arrow-left m-10" />
            </button>
        );
    }

    const settings = {
        dots: true,
        arrows: false, // Disable arrows by default
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true, // Enable auto-loop
        autoplaySpeed: 3000, // 3 seconds between slides
        swipeToSlide: true,
        touchThreshold: 10,
        responsive: [
            {
                breakpoint: 1024, // Tablet
                settings: {
                    arrows: false,
                    dots: true
                }
            },
            {
                breakpoint: 767, // Mobile
                settings: {
                    arrows: false,
                    dots: false,
                    centerMode: true,
                    slidesToShow: 1
                }
            }
        ]
    };
    
    // Remove SampleNextArrow and SamplePrevArrow components as we're not using arrows anymore

    useEffect(() => {
        console.log(location.search)
        if (productId) {
            fetchProduct();
        }
    }, [productId]);

    if (loading) {
        return <Preloader />;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    if (!product) {
        return <div>No product found</div>;
    }
   
    // Add these styles
    const styles = {
        mediaContainer: {
            height:'500px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'hidden',
            cursor: 'pointer'
        },
        media: {
            maxHeight: '100%',
            width: 'auto',
            maxWidth: '100%',
            objectFit: 'contain'
        },
        zoomOverlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0,0,0,0.9)',
            zIndex: 1000,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'zoom-out'
        },
        zoomedImage: {
            maxWidth: '90%',
            maxHeight: '90%',
            objectFit: 'contain'
        }
    };

    const handleMediaClick = (url) => {
        setSelectedImage(url);
        setIsZoomed(true);
    };
    return (
        <>
            {isZoomed && (
                <div style={styles.zoomOverlay} onClick={() => setIsZoomed(false)}>
                    <img 
                        src={selectedImage} 
                        alt="zoomed" 
                        style={styles.zoomedImage}
                        onClick={(e) => e.stopPropagation()}
                    />
                </div>
            )}
            
            <Helmet>
                <title>{product.nome || "dettaglio prodotto"}</title>
                <meta name="description" content={product.descrizione[0,159]} />
            </Helmet>
            
            <div className="product-details mt-32 padding-b-120">
                <div className="container container-two">
                    <div className="row gy-4 product-sidebar section-bg align-items-center">
                        <div className="col-1">
                            <Link to={{ pathname: "/prodotti/", search: location.search }}>
                                <button type="button" className="btn-new w-100 pill">
                                    <i className="fa fa-arrow-left" style={{ color: 'yellow' }}></i>
                                </button>
                            </Link>
                        </div>
                        <div className="col-10 text-center align-self-center">
                            <h4>Scheda prodotto</h4>
                        </div>
                        <div className="product-sidebar__top position-relative flx-between gap-1 col-12" />

                        <div className="tab-content" id="pills-tabContent">
                            <div
                                className="tab-pane fade show active"
                                id="pills-product-details"
                                role="tabpanel"
                                aria-labelledby="pills-product-details-tab"
                                tabIndex={0}
                            >
                                <div className="product-details__thumb text-center fw-bold mb-40">
                                    {product.nome}
                                </div>
                                <div className="product-details">
                                    <div className="row align-items-center justify-content-center">
                                        {media.length > 1 ? (
                                            <div className="col-8 col-lg-6 text-center">
                                                <Slider {...settings}>
                                                    {media.map((item, index) => (
                                                        item.type === "img" ? (
                                                            <div 
                                                                key={index} 
                                                                style={styles.mediaContainer}
                                                                onClick={() => handleMediaClick(item.url)}
                                                            >
                                                                <img 
                                                                    src={item.url} 
                                                                    alt={`slide ${index + 1}`} 
                                                                    style={styles.media} 
                                                                    className="rounded"
                                                                />
                                                            </div>
                                                        ) : (
                                                            <video 
                                                                key={index}
                                                                src={item.url} 
                                                                alt="video" 
                                                                controls 
                                                                autoPlay 
                                                                style={styles.media}
                                                                className="rounded"
                                                            />
                                                        )
                                                    ))}
                                                </Slider>
                                            </div>
                                        ) : media.length === 1 ? (
                                            <div className="col-10 col-lg-8 text-center">
                                                {media[0].type === "img" ? (
                                                    <div 
                                                        style={styles.mediaContainer}
                                                        onClick={() => handleMediaClick(media[0].url)}
                                                    >
                                                        <img 
                                                            src={media[0].url} 
                                                            alt="default" 
                                                            style={styles.media} 
                                                            className="rounded"
                                                        />
                                                    </div>
                                                ) : (
                                                    <video 
                                                        src={media[0].url} 
                                                        alt="default" 
                                                        controls 
                                                        autoPlay 
                                                        style={styles.media}
                                                        className="rounded"
                                                    />
                                                )}
                                            </div>
                                        ) : null}
                                    </div>
                                </div>

                                <div className="product-details__desc text-center">
                                    <div className="row justify-content-center align-items-center">
                                        <div className="col-4">codice : {product.codice}</div>
                                        <div className="col-4">marca : {product.marca !== null ? product.marca.nome : "non specificata"}</div>
                                    </div>
                                    <div className="mt-40" dangerouslySetInnerHTML={{__html:product.descrizione}}></div>
                                    <div className="mt-40" dangerouslySetInnerHTML={{__html:product.descrizione_completa}}></div>
                                </div>
                                <div className="product-sidebar__top position-relative flx-between gap-1 col-12" />
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="product-sidebar row justify-content-center">
                                {product.allegato !== "" ? (
                                    <>
                                        <div className="col-6">
                                            <button
                                                type="button"
                                                className="btn-new"
                                                onClick={goToContactPage}
                                            >
                                                <div style={{'color':'yellow'}}>
                                                Contattaci</div>
                                            </button>
                                        </div>
                                        <div className="col-6">
                                            <button
                                                type="button"
                                                className="btn-new "
                                            >
                                                <a href={product.allegato} download={true} style={{'color':'yellow'}}>
                                                Scarica la scheda tecnica
                                                </a>
                                            </button>
                                        </div>
                                    </>
                                ) : (
                                    <div className="col-6">
                                        <button
                                            type="button"
                                            className="btn-new"
                                            onClick={goToContactPage}
                                        >
                                            <div style={{'color':'yellow'}}>
                                            Contattaci</div>
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="comment-form mt-64 mb-64" id="comment-box">
                            <CommentForm name={formLayout.name} title={formLayout.title} layout={formLayout.layout} parent_id={productId}/>
                        </div>

                        <div className="comment mt-64 mb-64">
                            <h5 className="mb-32">{product.commenti.length} Commenti</h5>
                            <ul className="comment-list">
                                {product.commenti.map((commento, index) => (
                                    <Comment key={index} comment={commento} />
                                ))}  
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const ProductDetailsPage = () => {
    const {id} = useParams();
    return (
        <>
            <Preloader />
            <HeaderOne />
            <ProductDetails productId={id} />
            <FooterOne />
        </>
    );
}

export default ProductDetailsPage;