import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import Pagination from './Pagination';
const InstallazioneCard = ({installazione}) =>{
  return (
    <div className="col-lg-6 col-sm-6">
    <div className="post-item">
      <div className="post-item__thumb">
        <Link to={`/installazioni/${installazione.id}`} className="link">
          <img
            src={process.env.PUBLIC_URL + installazione.foto1}
            className="cover-img"
            alt=""
          />
        </Link>
      </div>
      <div className="post-item__content">
        <div className="post-item__top flx-align">
          <div className="post-item__date font-14 flx-align gap-2 font-14 text-heading fw-500">
            <span className="icon">
              <img
                src="assets/images/icons/calendar.svg"
                alt=""
                className="white-version"
              />
              <img
                src="assets/images/icons/calendar-white.svg"
                alt=""
                className="dark-version"
              />
            </span>
            <span className="text">{installazione.data}</span>
          </div>
        </div>
        <h5 className="post-item__title">
          <Link to={`/installazioni/${installazione.id}`}
  className="link">
            {installazione.titolo}
          </Link>
        </h5>
        <Link
          to={`/installazioni/${installazione.id}`}
          className="btn btn-outline-light pill fw-600"
        >
          Dettagli{" "}
        </Link>
      </div>
    </div>
  </div>)
}

const ArchivioInstallazioni = ({installazioni}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredInstallazioni,setFilteredInstallazioni] = useState(installazioni);
  
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  
  const installazioniPerPage = 2;
  const indexOfLastInstallazione = currentPage * installazioniPerPage;
  const indexOfFirstInstallazione = indexOfLastInstallazione - installazioniPerPage;
  const totalPages = Math.ceil(filteredInstallazioni.length / installazioniPerPage);
  const currentInstallazioni = filteredInstallazioni.slice(indexOfFirstInstallazione, indexOfLastInstallazione);
  
  return (
    <section className="blog padding-y-120  position-relative z-index-1 overflow-hidden ">
      <div className="container container-two ">
      <div className="section-heading style-center">
          <div className="section-heading__title mb-50" >
            <h2 className="color-title-new">Archivio Installazioni</h2>
          </div>
        </div>
        <div className="row gy-4 ">
            {currentInstallazioni.map((installazione) =>{return <InstallazioneCard installazione={installazione}></InstallazioneCard>})}
        </div>
        {/* Pagination Start */}
        <div className="col-12">
        <Pagination totalPages={totalPages} currentPage={currentPage} handlePageChange={handlePageChange} />
          </div>
        {/* Pagination End */}
      </div>
    </section>
  );
}

export default ArchivioInstallazioni;