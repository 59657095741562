import { Navigate } from "react-router-dom"
import Preloader from "../helper/Preloader"
import { useAuth } from "../provider/authProvider"
import Admin from "../components/Admin"
import HandleCatalog from "../components/HandleCatalog"
const HandleCatalogPage = () =>{
    const {token,loading} = useAuth()
    console.log(token)
    if (loading){
        return <Preloader />
    }
    if (!token){
        return <Navigate to='/login' replace />
    }
    return (<HandleCatalog />)
}
export default HandleCatalogPage;