import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import FooterOne from "../components/FooterOne";
import HeaderOne from "../components/HeaderOne";
import Preloader from "../helper/Preloader";

function SampleNextArrow(props) {
  const { className, onClick } = props;
  return (
    <button className={className} onClick={onClick}>
      <i className="las la-arrow-right" />
    </button>
  );
}

function SamplePrevArrow(props) {
  const { className, onClick } = props;
  return (
    <button className={className} onClick={onClick}>
      <i className="las la-arrow-left" />
    </button>
  );
}

const ServiceList = () => {
  const [services, setServices] = useState([]);

  useEffect(() => {
    fetch('/api/services')
      .then(res => res.json())
      .then(data => setServices(data));
  }, []);

  const sliderSettings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1400,
        settings: { slidesToShow: 3 }
      },
      {
        breakpoint: 992,
        settings: { slidesToShow: 2 }
      },
      {
        breakpoint: 768,
        settings: { slidesToShow: 1 }
      }
    ]
  };

  return (
    <section className="popular padding-y-120 overflow-hidden">
      <div className="container container-two">
        {/* Keep existing heading code */}

        <div className="arrow-style-two row gy-4 justify-content-center">
          <Slider {...sliderSettings}>
            {services.map(service => (
              <div key={service.id} className="px-3 h-100">
                <Link 
                  to={`/servizi/${service.id}`}
                  className="popular__item popular__item--secondary style-two hover__box d-flex flex-column h-100"
                >
                  <div className="popular__thumb popular__thumb--secondary overflow-hidden rounded-20 flex-grow-0" style={{ height: '200px' }}>
                    {service.foto1 && (
                      <img 
                        src={service.foto1} 
                        alt={service.titolo}
                        className="cover-img hover__box-child w-100 h-100 object-fit-cover"
                        style={{ maxWidth: '100%' }}
                      />
                    )}
                  </div>
                  <div className="popular__content popular__content--secondary mt-auto w-100">
                    <h4 className="popular__title mb-0 text-truncate">{service.titolo}</h4>
                  </div>
                </Link>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
};

const ServiceListPage = () => {
  return (
    <section className="change-gradient">
      <Preloader /> 
      <HeaderOne />
      <ServiceList />
      <FooterOne />
    </section>
  );
};

export default ServiceListPage;