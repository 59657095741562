import { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import Slider from "react-slick";
import FooterOne from "../components/FooterOne";
import HeaderOne from "../components/HeaderOne";
import Preloader from "../helper/Preloader";

function SampleNextArrow(props) {
  const { className, onClick } = props;
  return (
    <button className={className} onClick={onClick}>
      <i className="las la-arrow-right m-10" />
    </button>
  );
}

function SamplePrevArrow(props) {
  const { className, onClick } = props;
  return (
    <button className={className} onClick={onClick}>
      <i className="las la-arrow-left m-10" />
    </button>
  );
}

const ServiceDetail = () => {
  const { serviceId } = useParams();
  const [service, setService] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [images, setImages] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetch(`/api/services/${serviceId}`)
      .then(res => {
        if (!res.ok) throw new Error('Service non trovato');
        return res.json();
      })
      .then(data => {
        setService(data);
        setImages([data.foto1, data.foto2, data.foto3].filter(img => img && img !== ''));
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, [serviceId]);

  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  if (loading) return <Preloader />;
  if (error) return <div>{error.toString()}</div>;

  return (
    <section className="blog-details padding-y-120 position-relative overflow-hidden">
      <div className="container container-two">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="mb-64">
              <div className="flx-align gap-3 mb-4">
                <div className="col-1">
                  <Link to="/servizi">
                    <button type="button" className="btn-new w-100 pill">
                      <i className="fa fa-arrow-left" style={{'color':'yellow'}}></i>
                    </button>
                  </Link>
                </div>
                <h2 className="blog-details-top__title text-capitalize text-center w-100">
                  {service.titolo}
                </h2>
              </div>

              <div className="row justify-content-center mb-5">
                {images.length > 1 ? (
                  <div className="col-10 col-lg-8 text-center">
                    <Slider {...settings}>
                      {images.map((img, index) => (
                        <img key={index} src={img} alt={`slide ${index + 1}`} className="img-fluid rounded" />
                      ))}
                    </Slider>
                  </div>
                ) : (
                  <div className="col-10 col-lg-8 text-center">
                    <img src={service.foto1} alt="" className="img-fluid rounded" />
                  </div>
                )}
              </div>

              <div className="blog-details-content">
                <div className="row justify-content-center">
                  <div className="col-12" dangerouslySetInnerHTML={{__html: service.descrizione}}></div>
                </div>

                <div className="row justify-content-center mt-40">
                  <div className="col-8 text-center">
                    <button
                      type="button"
                      className="btn-new pill w-100"
                      onClick={() => navigate('/contatti')}
                    >
                      <span className="fw-bold fs-5" style={{'color':'yellow'}}>
                        Chiedi una consulenza senza impegno!
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const ServiceDetailPage = () => {
  return (
    <section className="change-gradient">
      <Preloader /> 
      <HeaderOne />
      <ServiceDetail />
      <FooterOne />
    </section>
  );
};

export default ServiceDetailPage;