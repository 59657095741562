import { useState } from "react";
import { Controller } from "react-hook-form";

const FileInput = ({ fieldName, filePath, control }) => {
  const [file, setFile] = useState(
    filePath ? { name: filePath, type: filePath.split(".")[1] } : null
  );

  const handleFileChange = (e, onChange) => {
    if (e.target.files && e.target.files[0]) {
      const selectedFile = e.target.files[0];
      setFile({
        name: selectedFile.name,
        type: selectedFile.type.split("/")[1], // Extract file extension
      });
      onChange(selectedFile); // Update form state with the new file
    }
  };

  const handleDelete = (onChange) => {
    setFile(null); // Clear the file preview
    onChange(null); // Update form state to indicate deletion
  };

  return (
    <div className="mb-3">
      <label htmlFor={fieldName} className="form-label mt-3">
        {fieldName}
      </label>
      <div className="d-flex align-items-center">
        <Controller
          control={control}
          name={fieldName}
          render={({ field: { value, onChange, ...field } }) => (
            <>
              <input
                {...field}
                onChange={(e) => handleFileChange(e, onChange)}
                type="file"
                id={fieldName}
                className="form-control"
              />
              {file && (
                <button
                  type="button"
                  className="btn btn-danger ms-2"
                  onClick={() => handleDelete(onChange)}
                >
                  Delete
                </button>
              )}
            </>
          )}
        />
      </div>
      {file && (
        <div className="mt-2">
          <strong>Selected File:</strong> {file.name} ({file.type})
        </div>
      )}
    </div>
  );
};

export default FileInput;