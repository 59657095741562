import React, { useState } from 'react';
import { Label, Row, Col } from 'reactstrap';
import Preloader from '../helper/Preloader';
import { useForm, Controller } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useAuth } from '../provider/authProvider';
import { useNavigate } from 'react-router-dom';
import FileInput from './FileInput';
import axios from 'axios'; // For making API requests

const HandleCatalog = () => {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const auth = useAuth();

  // Function to handle file upload
  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      alert('Please select a file.');
      return;
    }
  
    const formData = new FormData();
    formData.append('file', file); // Ensure the key is 'file'
  
    try {
      setLoading(true);
      const response = await axios.post('/api/admin/import_products', formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Ensure the correct content type
          Authorization: `Bearer ${auth.token}`, // Add auth token if needed
        },
      });
      alert(response.data.message); // Show success message
    } catch (error) {
      console.error('Error uploading file:', error);
      alert('Error uploading file. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Function to handle export products
  const handleExportProducts = async () => {
    try {
      setLoading(true);
      const response = await axios.get('/api/admin/export_products', {
        headers: {
          Authorization: `Bearer ${auth.token}`, // Add auth token if needed
        },
        responseType: 'blob', // For downloading files
      });

      // Create a download link for the exported file
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'products.csv'); // Set the file name
      document.body.appendChild(link);
      link.click();
      link.remove(); // Clean up
    } catch (error) {
      console.error('Error exporting products:', error);
      alert('Error exporting products. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Preloader />;
  }

  return (
    <section className="position-relative z-index-1 overflow-hidden">
      <div className="section-heading style-center">
        <div className="section-heading__title mb-50 row">
          <div className="col-1">
            <Link to="/admin">
              <button type="button" className="btn btn-main w-100 pill">
                <i className="fa fa-arrow-left"></i>
              </button>
            </Link>
          </div>
          <div className="col-10">
            <h2>Gestione Catalogo</h2>
          </div>
        </div>
      </div>
      <div className="row align-items-center justify-content-center m-3">
        <div className="col-12 section-bg">
          <div className="p-4">
            <h4>Importa Prodotti</h4>
            <input
              type="file"
              accept=".csv"
              onChange={handleFileUpload}
              disabled={loading}
            />
            <p className="text-muted mt-2">Carica un file CSV per aggiornare i prodotti. separato da virgola</p>
          </div>
          <div className="p-4">
            <h4>Esporta Prodotti</h4>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleExportProducts}
              disabled={loading}
            >
              Esporta Prodotti
            </button>
            <p className="text-muted mt-2">Scarica un file CSV con tutti i prodotti.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HandleCatalog;