import React from "react";
import Preloader from "../helper/Preloader";
import HeaderOne from "../components/HeaderOne";
import FooterOne from "../components/FooterOne";
import BlogDetails from "../components/BlogDetails";
import InstallazioneDetails from "../components/InstallazioneDetails";
const InstallazioneDetailsPage = () => {

  return (
    <>
      {/* Preloader */}
      <Preloader />

      {/* HeaderOne */}
      <HeaderOne />

      {/* BlogDetails */}
      <InstallazioneDetails />

      {/* FooterOne */}
      <FooterOne />
    </>
  );
};

export default InstallazioneDetailsPage;
