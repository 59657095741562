import React from "react";
import { Link, useLocation } from "react-router-dom";
const ProductCard = ({ product,search }) => {
  const location = useLocation();
  return (
    <div className="col-lg-4 col-sm-6 col-xl-3 col-6">
    <div className="product-item section-bg">
      <div className="product-item__thumb d-flex">
        <Link to={{pathname:`/dettagli_prodotto/${product.id}`, search: search}} className="link w-100">
          {/* Add aspect ratio container */}
          <div className="product-image-container">
            <img
              src={product.foto1}
              alt={product.nome || "Prodotto"}
              className="product-image img-fluid"
              style={{ 
                objectFit: 'fit', 
                width: '100%', 
                height: '100%' 
              }}
            />
          </div>
        </Link>
      </div>
        <div className="product-item__content">
          <h6 className="product-item__title">
            <Link to={{pathname:`/dettagli_prodotto/${product.id}`, search:search}} className="link">
              {product.nome}
            </Link>
          </h6>
          <div className="product-item__info flx-between gap-2">
            {product.marca && (
              <span className="product-item__author">{product.marca.nome}</span>
            )}
          </div>
          <div className="product-item__bottom flx-between gap-2">
            <Link
              to={{pathname:`/dettagli_prodotto/${product.id}`,search:search}}
              className="btn btn-outline-light btn-sm pill"
            >
              Dettagli
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};


export default ProductCard;