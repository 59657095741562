import React, { useEffect, useState } from 'react';
import { Label } from 'reactstrap';
import Preloader from '../helper/Preloader';
import { useForm,Controller } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../provider/authProvider';
import FileInput from './FileInput';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import RichTextEditor from './RichTextEditor';

const InstallazioneForm = ({ installazione}) => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [date, setDate] = useState(installazione?.data ? new Date(installazione.data) : new Date());
  const { control, handleSubmit, register, getValues, setValue,formState:{errors} } = useForm({ defaultValues: installazione });
  
 
  const textFields = [
    { value: installazione ? installazione.titolo : "", label: 'titolo' ,validation:{required:true}},
  ];
  const textAreaFields = [
    { value: installazione ? installazione.descrizione : "", label: 'descrizione' ,validation:{}},
  ];


  const imageFields = [installazione ? installazione.foto1 : ""];
  useEffect(() =>{
    setValue("data", date?.toISOString().replace("Z","+00:00"))
  },[date])
  const handleDelete = async () =>{
    // delete request
    const response = await fetch(`/api/admin/installazioni/${installazione.id}`,{
      method: "DELETE",
      headers: {
        "Authorization": "Bearer " + auth.token,
      },
      body: new FormData()
    })

    if (response.ok){
      alert("oggetto eliminato correttamente")
      navigate('/admin')
    }else{
      alert("errore eliminazione oggetto")
    }
  }
  const handleNewTag = () =>{
    
  }
  const onSubmit = (data) => {
    console.log(data);
    if (JSON.stringify(data) === JSON.stringify(installazione)) {
      alert('Form data is equal to default values. Submission prevented.');
      return;
    }
    
    const formData = new FormData();
    if (data.foto1 && data.foto1 instanceof File) {
      formData.append("foto1", data.foto1);
    }
    
    data = {
      ...data,
      foto1: data.foto1 === null ? null : // Preserve null for deletion
             data.foto1 && data.foto1 instanceof File ? `/assets/images/installazioni/${data.foto1.name}` : // New file
             installazione?.foto1 ? installazione.foto1 : "", // Existing file or empty string
    };

    formData.append("data", JSON.stringify(data));
    
    let url = "/api/admin/installazioni";
    let method = "POST";
    
    if (data && data.id) {
      url = `/api/admin/installazioni/${data.id}`;
      method = "PATCH";
    }
    fetch(url, {
      method: method,
      headers: {
        "Authorization": "Bearer " + auth.token,
      },
      body: formData
    })
      .then(response => {
    if (response.ok){
        let str_resp = method === "PATCH" ? "modifica avvenuta correttamente" : "creazione avvenuta correttamente"
        alert(str_resp)
        navigate("/admin")
    }else if(response.status === 401){
      alert("sessione interrotta, rieffettuare il login")
      navigate("/login")
    } 
    else{
      alert("errore : " + response.json())
    }
  })
      .then(data => console.log(data))
      .catch(error => console.error('Error:', error));
  };

  return (
    <>
    <form onSubmit={handleSubmit(onSubmit)}>
    
    
    {textFields.map(field => (
        <><label htmlFor={field.label} className="form-label mt-3">{field.label}{field.validation.required && "*"}:{" "}</label>
        <input type="text" className={`form-control ${field.className}`} {...register(field.label,field.validation)} />
        {errors[field.label] && (
          <p className="red-text">Questo campo è obbligatorio</p>
          )}</>
      ))}
      {textAreaFields.map(field => (
        <div key={field.label}>
          <label htmlFor={field.label} className="form-label mt-3">
            {field.label}{field.validation.required && "*"}:
          </label>
          <Controller
            name={field.label}
            control={control}
            defaultValue={field.value} // Ensure default value is set
            render={({ field }) => (
              <RichTextEditor
                value={field.value || ''} // Ensure value is not undefined
                onChange={(content) => {
                  field.onChange(content); // This updates the form state
                }}
              />
            )}
          />
          {errors[field.label] && (
            <p className="red-text">Questo campo è obbligatorio</p>
          )}
        </div>
      ))}
      <div>
              <label htmlFor="data" className="form-label mt-3">data:</label>
              <DatePicker
                selected={date}
                onChange={(date) => setDate(date)}
                showTimeSelect
                dateFormat="Pp"
              /></div>
      <div>
        {imageFields.map((img, index) => (
          <FileInput key={index} fieldName={`foto${index + 1}`} filePath={img ? img : ""} control={control} />
        ))}
       
      </div>

      <button type="submit" className="btn btn-primary mt-3">Save</button>
    </form>
     <div>
     {installazione && <button  className="btn btn-primary m-3" onClick={handleDelete}>Elimina</button>}
   </div></>
  );
};

const InstallazioneManager = ({ idInstallazione }) => {
  const [loading, setLoading] = useState(true);
  const [installazione, setInstallazione] = useState(null);


  useEffect(() => {
    async function fetchData() {
      let installazioneResponse = null;
      let installazioneData = null;

      if (idInstallazione !== null){
        installazioneResponse = await fetch(`/api/installazioni/${idInstallazione}`);
      }
      
     if ((installazioneResponse !==null && !installazioneResponse.ok) ) {
        console.log('error');
        return;
      }
      if (installazioneResponse !== null){
        installazioneData = await installazioneResponse.json();
      }
      
    return [installazioneData];
    }

    fetchData().then((data) =>{
        setInstallazione(data[0])
        setLoading(false);
    }).catch(error => {
      console.log(error);
      setLoading(false);
    });
  }, [,idInstallazione]);

 
  if (loading) {
    return <Preloader />;
  }

  return (
    <section className=" position-relative z-index-1 overflow-hidden">
    <div className="section-heading style-center">
        <div className="section-heading__title mb-50" >
          <h2>{installazione !== null ? "modifica installazione" : "inserisci nuova installazione"}</h2>
        </div>
      </div>
    <div className="row align-items-center justify-content-center mb-50">
    <div className="col-1">
        <Link to="/admin">
            <button type="button" className="btn btn-main w-100 pill">
                <i className="fa fa-arrow-left"></i>
            </button>
        </Link>
    </div>
    </div>
    <div className="row align-items-center justify-content-center m-3">
      <div className="col-12 section-bg">
        <InstallazioneForm installazione={installazione} />
       </div>
       </div>
       </section> 
  );
};

export default InstallazioneManager;
